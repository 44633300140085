import React, { useState, useCallback, useEffect } from "react";
import './App.css';
import { List } from "antd";
import axios from './axios';

const SiteNews = (props) => {

  //const [isErr,setIsErr] = useState(false);
  const [postData,setPostData] = useState([]);

  const sortPosts = ((a,b) => {
    //console.log (a.created)
    if (a.created >= b.created){
      return -1;
    }
    return 1;
  })

    //const data = ["Cade York craters after being cut", "Puka, Love both drop over 20% after injuries", "Isiah Likely leads Week 1 with 27% gain", "Congrats to @sruppert12 on $10 win for highest Week 1 gain!", "Welcome to the 2024 NFL Season!"];

    const fetchPosts =  useCallback(async() => {

      var data2 = [];
      setPostData([]);
      try {
        console.log("get site posts");

        const res = await axios.get('/getpost/all');
        //console.log(res);
        //console.log(res.data); //debugger
        for (let i=0; i<res.data.length; ++i){
          const record = res.data[i];
          data2.push(record);
        }
        //console.log(data2);
        data2.sort(sortPosts)
        //setLoading(false);
        //console.log(data2);
        setPostData(data2.map( (post) => post.msg));

      }
      catch (err) {
        console.log(err);
        //setIsErr(true);
        //setLoading(false);
      }
    },[]);

    useEffect( () => {
      fetchPosts();
    }, [fetchPosts]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
    {/*<div class="module-header">
        <header class="module-list content">
            <h2>Site News</h2>
        </header>
        <ul class="module-list content">{data[0]}</ul>
    </div> */}
    <div>
    {postData.length>0 && <List size="small" header={<div>Site News</div>} bordered dataSource={postData} renderItem={(item) => <List.Item>{item}</List.Item>}>
    </List>}
    </div></div>
  );
}

export default SiteNews;
