

import React, { useState, useEffect, useCallback } from "react";

import axios from './axios.js';

import useAxiosPrivate from "./hooks/useAxiosPrivate.js";

import { Button, Dropdown, Menu, Input, InputNumber,Form, Select, Checkbox } from "antd";

import {DownOutlined} from '@ant-design/icons';

import {NFLteamMap, NFLteamItems, positionOptions, positionItems, positionMap} from './CONSTANTS.js';


const { Option} = Select;

const  AdminStock = (props) => {

  /*const defaultTimeframe = {
    name:"NCAAM",
    value: 10,
    league: 6,
    season: 3, //playoffs
  }; */

  /*const defaultTimeframe = {
    name:"NFL",
    value: 8,
    league: 1,
    season: 3, //playoffs
  }; */


    const defaultTimeframe = {
    name:"NFL",
    value: 7,
    league: 1,
    season: 2, //regular season
  };  //NFl regular season


  const labelSorter = ((a,b)=>{
    if (a.label>b.label){
      return 1;
    }
    return -1;
  })

  const positionSorter = ( (a,b) => {
    //console.log(a.team)
    //console.log(teamMap.get(a.team))
    if (teamMap.get(a.team)>teamMap.get(b.team)){
      return 1;
    }
    return -1;
  })

  
  NFLteamItems.sort(labelSorter);

  const [view,setView] = useState("All");
  const [prevView,setPrevView] = useState("");

  const axiosPrivate = useAxiosPrivate();
  //const [leagueName,setLeagueName] = useState("Select A League");
  const [league,setLeague] = useState(defaultTimeframe.league);
  const [leagueName,setLeagueName] = useState(defaultTimeframe.name);
  const [team, setTeam] = useState(-1);
  const [newTeam,setNewTeam] = useState(-1);
  const [teamName, setTeamName] = useState("Select A Team");
  const [newTeamName,setNewTeamName] = useState("Select New Team");
  const [teamItems, setteamItems] = useState(NFLteamItems);
  const [teamMap, setteamMap] = useState(NFLteamMap);
  const [playerItems, setplayerItems] = useState([]);
  const [playerMap, setplayerMap] = useState(new Map());
  const [playerName,setPlayerName] = useState("Select A Player");
  const [positionName,setPositionName] = useState("Select A Position");
  //const [position,setPosition] = useState(-1);
  const [player,setPlayer] = useState(-1);
  const [isSuccess,setIsSuccess] = useState(false);
  const [season,setSeason] = useState(defaultTimeframe.season);
  //const [seasonName,setSeasonName] = useState("Playoffs");
  const [timeframe,setTimeframe] = useState(defaultTimeframe.value);

  const fillTeamMenu = useCallback((league) => {
    console.log("Filling the teams");

    //console.log("teamItems old")
    //console.log(teamItems)
    if (league===1){
      console.log("teamItems new")
      console.log(NFLteamItems)
    
      console.log("local team Map")
    
      console.log(NFLteamMap);
      NFLteamItems.sort(labelSorter);
      setteamItems(NFLteamItems);
      setteamMap(NFLteamMap);
    }
    else {
      var myMap = new Map();
      const data = {
        sport_league_id: league
      }
      axios.post('/getmarket/leagueTeams',data)
      .then((res) => {
        console.log("res is " + res);
        console.log("res.data is " + res.data); //debugger
        console.log(res.data);
        var teamItemsloc = [];
        for (let i=0; i<res.data.length; ++i){
          var obj;
          var record = res.data[i];
          obj = {
            label: record.city + " " + record.nickname,
            key: record.team_id

          }
          //console.log("Obj " + i)
          //console.log(obj)
          myMap.set(record.team_id,obj.label)
          teamItemsloc.push(obj)
        }
        teamItemsloc.sort(labelSorter);
        setteamItems(teamItemsloc);
        setteamMap(myMap);
    
      })

    }
  },[]);

  useEffect( () => {
    fillTeamMenu(league);
  }, [fillTeamMenu]); // eslint-disable-line react-hooks/exhaustive-deps


  const successfulUpdate = () => {
    setIsSuccess(true);
    setPrevView(view);
    setView("All");
  }

  const repeatAction = () => {
    setView(prevView);
    setIsSuccess(false);
  }

  const leagueUpdate = (lg) => {
    //add more logic when other seasons added
    console.log("League Update: " + lg)
    if (lg!==league){

      setTeam(-1);
      setTeamName("Select A Team");
      setPlayerName("Select A Player");
      fillTeamMenu(lg);
    }
    setLeague(lg);
    if (lg===1){
      setLeagueName("NFL");
      determineTimeframe(lg,season);

    }
    else if (lg===6){
      setLeagueName("NCAAM");
      determineTimeframe(lg,season);
    }
    else {
      console.log("Bad league id ")
      setLeague(-1);
      setLeagueName("Select A League");
      setTimeframe(-1);
    }

  }

  const seasonUpdate = (szn) => {
    console.log("setting season to: " + szn)
    if (szn!==season) {
      setTeam(-1);
      setTeamName("Select A Team");
      setPlayerName("Select A Player");
    }
    setSeason(szn);

    determineTimeframe(league,szn);
  }

  const determineTimeframe = (lg,szn) => {
    console.log("season is  " + szn)
    if (lg===1){
      if (szn===2){
        setTimeframe(7);
      }
      else if (szn === 3){
        setTimeframe(8);
      }
    }
    else if (lg===6){
      if (szn===3){
        setTimeframe(10);
      }
    }
    else {
      setTimeframe(-1);
    }

  }


  const submitNewPlayer = async (e) => {
    console.log ("Submitting new player");
    console.log("Adding Player to team id " + String(team) + ", the " + teamName);
    console.log(e);


    //to get team   -- Document.getelementbyid - so need to assign id to <Input> boxes
    //console.log(e.team);
    //console.log("ret " + ret);
    //console.log("Access");
    //console.log(accessToken);
    console.log(team);
    console.log(league);
    console.log("price given is " + e.p_price);
    console.log("dividend given is " + e.p_dividend);
    if (leagueName==='NFL'){
      //send NFL post to add team to db for nflteams
      var data = {
        league: league,
        team: team,
        sport: 1,
        fname: e.p_fname,
        lname: e.p_lname,
        pos : e.p_pos,
        rookie: e.p_rookie,
        createstock: true,
        savehist: e.p_savehist,
        timeframe: timeframe
      }
      if (e?.p_price){
        data.price = e.p_price
      }
      if (e?.p_dividend){
        data.dividend = e.p_dividend
      }
      try{
        console.log("attempting to send data to server");
        console.log(data);
        //const response = await axios.post('add_player',data);
        const response =  await axiosPrivate.post('/player/add',data);
        console.log ("Response");
        console.log (response);
        successfulUpdate();
      }
      catch(err){
        console.log("error in add player: " + err);
      }
    }

  }

  const submitNewPlayerPriceAndDividend = async (e) => {
    console.log("Adding Player Price and Div to " + playerName);
    console.log(e);

    if (leagueName==='NFL'){
      //send NFL post to add team to db for nflteams
      const data = {
        league: league,
        team: team,
        player: player,
        ent_id: player,
        price: e.price,
        dividend: e.dividend,
        timeframe: timeframe
      }
      console.log(data);
      const response = await axiosPrivate.post('/market/updateStockPrice',data);
      console.log ("response")
      console.log(response);
      successfulUpdate();
    }

  }

  const submitNewTeamPriceAndDividend = async (e) => {
    console.log("Adding Team Price to " + teamName);
    console.log(e);

    if (league!==-1){
      const data = {
        league: league,
        team: team,
        ent_id: team,
        price: e.price,
        dividend: e.dividend,
        timeframe: timeframe
      }
      console.log(data);
      const response = await axiosPrivate.post('/market/updateStockPrice',data);
      console.log ("response")
      console.log(response);
      successfulUpdate();
    }

  }

  const submitNewPlayerPrice = async (e) => {
    console.log("Adding Player Price to " + playerName);
    console.log(e);

    if (leagueName==='NFL'){
      //send NFL post to add team to db for nflteams
      const data = {
        league: league,
        team: team,
        player: player,
        ent_id: player,
        price: e.price,
        timeframe: timeframe
      }
      console.log(data);
      const response = await axiosPrivate.post('/market/updateStockPrice',data);
      console.log ("response")
      console.log(response);
      successfulUpdate();
    }

  }

  const submitPlayerDividend = async (e) => {
    console.log("Updating Player dividend for " + playerName);
    console.log(e);

    if (leagueName==='NFL'){
      //send NFL post to add team to db for nflteams
      const data = {
        league: league,
        team: team,
        player: player,
        ent_id: player,
        price: e.price,
        timeframe: timeframe
      }
      console.log(data);
      const response = await axiosPrivate.post('/market/updateDividend',data);
      console.log ("response")
      console.log(response);
      successfulUpdate();
    }

  }


  const submitNewPlayerTeam = async (e) => {
    console.log("Updating team for Player " + playerName);
    console.log(e);

    const data = {
      team: newTeam,
      player: player,
      ent_id: player,
      timeframe: timeframe
    }
    console.log(data);
    const response = await axiosPrivate.post('/player/updatePlayerTeam',data);
    console.log ("response")
    console.log(response);
    successfulUpdate();


  }

  const submitNewTeamPrice = async (e) => {
    console.log("Adding Team Price to " + teamName);
    console.log(e);



    if (league!==-1){
      const data = {
        league: league,
        team: team,
        ent_id: team,
        price: e.price,
        timeframe: timeframe
      }
      console.log(data);
      const response = await axiosPrivate.post('/market/updateStockPrice',data);
      console.log ("response")
      console.log(response);
      successfulUpdate();
    }

  }

  const submitTeamDividend = async (e) => {
    console.log("Updating Team dividend for " + teamName);
    console.log(e);

    if (league!==-1){
      const data = {
        league: league,
        team: team,
        ent_id: team,
        price: e.price,
        timeframe: timeframe
      }
      console.log(data);
      const response = await axiosPrivate.post('/market/updateDividend',data);
      console.log ("response")
      console.log(response);
      successfulUpdate();
    }

  }

  const restrictSportLeagueTrading = async (e,restriction) => {
    console.log("Resticting trading for league " + league);
    console.log(e);

      const data = {
        league: league,
        restrict: restriction,
        timeframe: timeframe
      }
      console.log(data);
      const response = await axiosPrivate.put('/market/restrictLeagueTrading',data);
      console.log ("response")
      console.log(response);
      successfulUpdate();


  }

  const restrictTeamTrading = async (e,restriction) => {
    console.log("Resticting trading for " + teamName + " and players on that team");
    console.log(e);

      const data = {
        league: league,
        team: team,
        ent_id: team,
        restrict: restriction,
        timeframe: timeframe
      }
      console.log(data);
      const response = await axiosPrivate.put('/market/restrictTeamTrading',data);
      console.log ("response")
      console.log(response);
      successfulUpdate();


  }

  const restrictTeamStockTrading = async (e,restriction) => {
    console.log("Restricting trading for " + teamName + " stock only.");
    console.log(e);

      const data = {
        league: league,
        team: team,
        ent_id: team,
        restrict: restriction,
        timeframe: timeframe
      }
      console.log(data);
      const response = await axiosPrivate.put('/market/restrictTeamStockTrading',data);
      console.log ("response")
      console.log(response);
      successfulUpdate();
  }

  const restrictNFLplayerTrading = async (e,restriction) => {
    console.log("Resticting trading for " + playerName);
    console.log(e);

      const data = {
        league: 1,
        team: team,
        player: player,
        ent_id: player,
        restrict: restriction,
        timeframe: timeframe
      }
      console.log(data);
      const response = await axiosPrivate.put('/market/restrictPlayerTrading',data);
      console.log ("response")
      console.log(response);
      successfulUpdate();

  }

  const restrictNFLpositionTrading = async (e,restriction) => {
    console.log("Resticting trading for " + e.position);
    console.log(e);
    console.log(restriction);

    const data = {
      league: 1,
      position: e.position,
      restrict: restriction,
      timeframe: timeframe
    }
    console.log(data);
    const response = await axiosPrivate.put('/market/restrictPositionTrading',data);
    console.log ("response")
    console.log(response);
    successfulUpdate();

  }

  const restrictFFleagueTrading = async (e,tradeable) => {
    console.log("Resticting trading for FF league " + leagueName);
    console.log(e);

      const data = {
        league: league,
        tradeable: tradeable,
        timeframe: timeframe
      }
      console.log(data);
      const response = await axiosPrivate.put('/league/setFFLeagueTradeable',data);
      console.log ("response")
      console.log(response);
      successfulUpdate();


  }

  const activatePlayerStock = async (e,activation) => {
    console.log("Activation or In-activation for " + playerName);
    console.log(e);

      const data = {
        player: player,
        ent_id: player,
        activate: activation,
        timeframe: timeframe
      }
      console.log(data);
      console.log('/market/activate')
      const response = await axiosPrivate.put('/market/activate',data);
      console.log ("response")
      console.log(response);
      successfulUpdate();

  }

  const saveStockHistory = async (e) => {
    e.timeframe = timeframe;
    await axiosPrivate.put('/market/storeLeagueStockHistory',e);
    successfulUpdate();
  }

  const updateSingleStockHistory = async(e) => {
    const data = {
      league: league,
      team: team,
      player: player,
      ent_id: player,
      timeframe: timeframe
    }
    await axiosPrivate.put('/market/updateStockHistory',data);
    successfulUpdate();
  }

  const savePortHistory = async (e) => {
    e.timeframe = timeframe;
    await axiosPrivate.put('/portfolio/storeHistory',e);
    successfulUpdate();
  }

  const savePortEquityHistory = async (e) => {
    e.timeframe = timeframe;
    await axiosPrivate.put('/portfolio/storeEquityHistory',e);
    successfulUpdate();
  }

  const savePortRosterHistory = async (e) => {
    e.timeframe = timeframe;
    await axiosPrivate.put('/portfolio/storeRosterHistory',e);
    successfulUpdate();
  }

  const payoutDividends = async () => {
    const data = {
      timeframe: timeframe
    }
    await axiosPrivate.put('/portfolio/payoutDividends',data);
    successfulUpdate();
  }

  const incrementMultipliers = async () => {
    const data = {
      timeframe: timeframe
    }
    await axiosPrivate.put('/portfolio/incrementMultipliers',data);
    successfulUpdate();
  }

  const exportStockToTimeframe = async (e) => {
    console.log(e);
    const data = {
      league: league,
      ent_id: player,
      team_id: team,
      year: e.year,
      type: e.timeframe,
      timeframe: timeframe
    }
    await axiosPrivate.put('/market/createStockInTimeframe',data);
    successfulUpdate();
  }

  const exportTeamStockToTimeframe = async (e) => {
    console.log(e);
    const data = {
      league: league,
      ent_id: team,
      team_id: team,
      year: e.year,
      type: e.timeframe,
      timeframe: timeframe
    }
    await axiosPrivate.put('/market/createStockInTimeframe',data);
    successfulUpdate();
  }

  const exportTeamToTimeframe = async (e) => {
    console.log(e);
    const data = {
      league: league,
      team: team,
      year: e.year,
      type: e.timeframe
    }
    await axiosPrivate.put('/market/createStocksForTeamInTimeframe',data);
    successfulUpdate();
  }

  const incrementTimeframe = async (e) => {
    const data = {
      timeframe: timeframe
    }
    await axiosPrivate.post('/market/incrementTimeframe',data);
    successfulUpdate();
  }

  const updateSubtimeframe = async (e) => {
    const data = {
      timeframe: timeframe,
      subtimeframe: e.subtimeframe
    }
    await axiosPrivate.post('/market/updateSubtimeframe',data);
    successfulUpdate();
  }

  const autoUpdateStocks = async (e) => {
    const data = {
      league: league,
      timeframe: timeframe
    }
    await axiosPrivate.post('/market/updateStockPrices',data);
    successfulUpdate();
  }

  const autoUpdateTeamStocks = async (e) => {
    const data = {
      league: league,
      timeframe: timeframe
    }
    await axiosPrivate.post('/market/updateTeamStockPrices',data);
    successfulUpdate();
  }


  const handleTeamMenuClick = (e) => {
    //console.log("click ", e);
    console.log("Handle Team Menu Click");
    console.log("Choose Team");
    console.log(e);

  };

  const handleTeamClick = (e) => {
    //console.log("click ", e);
    console.log("Handle Team Click");
    console.log(e);
    console.log("team key: " + e.key);
    console.log(e.key);
    console.log(teamMap)
    console.log("team corresponding to key: " + teamMap.get(parseInt(e.key)))
    setTeam(parseInt(e.key));
    setTeamName(teamMap.get(parseInt(e.key)))
    setPlayerName("Select A Player");
    if (view==='Price' || view==="PlayerTrade" || view==="PlayerDiv" || view==="stockinactive" || view==="NFLplayerunlock" || 
        view==="NFLplayerlock" || view==="playerhistoryupdate" || view==="PriceAndDiv") {
      fillPlayerMenu(e.key,-1,true);
    }
    else if (view==="timeframecreate"){
      fillPlayerMenu(e.key,-1,false)
    }
  };


  const handleNewTeamClick = (e) => {
    //console.log("click ", e);
    console.log("Handle New Team Click");
    console.log(e);
    console.log("team key: " + e.key);
    console.log(e.key);
    console.log(teamMap)
    console.log("team corresponding to key: " + teamMap.get(parseInt(e.key)))
    setNewTeam(e.key);
    setNewTeamName(teamMap.get(parseInt(e.key)))
  };

  const getInactivePlayers = async() =>{
    setPlayer(-1);
    setPlayerName("Select A Player");
    try {
      const data = {timeframe:timeframe};
      const response = await axiosPrivate.post('/getmarket/inactive',data);
      console.log(response.data);
      var playerItemsLoc = [];
      var myMap = new Map();
      for (let i=0; i<response.data.length; ++i){
        const record = response.data[i];
        const playerName = record.fname + " " + record.lname;
        const pid = record.player_id;
        playerItemsLoc.push({
            label: playerName,
            key: pid
        })
        myMap.set(pid,playerName)
      }
      console.log("playerItems")
      console.log(playerItemsLoc)
      playerItemsLoc.sort(labelSorter);
      setplayerItems(playerItemsLoc)
      setplayerMap(myMap);
    }
    catch (err) {

    }
  }

  const fillPlayerMenu = (team,position,specificTimeFrameOnly) => {
    console.log("Filling the players");
    console.log("team is " + team)
    console.log("position is " + position)
    var data = {}
    if (team>-1){
      data.team = team;
    }
    if (position > -1) {
      data.position = position;
    }
    if (specificTimeFrameOnly){
      data.timeframe = timeframe;
    }
    var myMap = new Map();
    console.log(data);
    axios.post('/getmarket/players',data)
    .then((res) => {
      console.log(res);
    console.log("res.data is " + res.data); //debugger
    console.log(res.data);
    var playerItemsLoc = [];

    for (let i=0; i<res.data.length; ++i){
      const record = res.data[i];
      const playerName = record.fname + " " + record.lname;
      const pid = record.player_id;
      playerItemsLoc.push({
          label: playerName,
          key: pid,
          team: record.team_id
      })
      myMap.set(pid,playerName)
    }
    console.log("playerItems")
    console.log(playerItemsLoc)
    if (position > -1 ){
      playerItemsLoc.sort(positionSorter)
    }
    else {
      playerItemsLoc.sort(labelSorter);
    }
    setplayerItems(playerItemsLoc)
    setplayerMap(myMap);

    console.log(playerItems);
  })
};

  const handlePlayerClick = (e) => {
    //console.log("click ", e);
    console.log("Chose Player");
    console.log(e);
    console.log(e.key);
    setPlayer(parseInt(e.key));
    setPlayerName(playerMap.get(parseInt(e.key)))
  };

  const handlePositionClick = (e) => {
    //console.log("click ", e);
    console.log("Chose Position");
    console.log(e);
    console.log(e.key);
    //setPosition(parseInt(e.key));
    setPositionName(positionMap[parseInt(e.key)])
    setTeamName("Select A Team");
    setTeam(-1);
    setPlayerName("Select A Player");
    fillPlayerMenu(-1,e.key,true);
  };

  const handlePositionMenuClick = (e) => {
    //console.log("click ", e);
    console.log("Handle Position Menu Click");
    console.log("Choose Position");
    console.log(e);

  };


  const handlePlayerMenuClick = (e) => {
    //console.log("click ", e);

    console.log("Handle Player Menu Click");
    console.log("Choose a Player");
    console.log(e);

  };


  const TeamMenu = () => (
    <Menu onClick={handleTeamClick} items={teamItems}>
    </Menu>
  );

  const NewTeamMenu = () => (
    <Menu onClick={handleNewTeamClick} items={teamItems}>
    </Menu>
  );

  const NFLPositionMenu = () => (
    <Menu onClick={handlePositionClick} items={positionItems}>
    </Menu>
  );


  const PlayerMenu = () => (
    <Menu onClick={handlePlayerClick} items = {playerItems}>
    </Menu>
  );


  const ChooseTeam = () => (
    <Dropdown trigger={['click']} overlay={TeamMenu}>
      <Button onClick={handleTeamMenuClick}>{teamName}<DownOutlined /></Button>

    </Dropdown>
  );

  const ChooseTeamNew = () => (
    <Dropdown trigger={['click']} overlay={NewTeamMenu}>
      <Button onClick={handleTeamMenuClick}>{newTeamName}<DownOutlined /></Button>

    </Dropdown>
  );


  const ChoosePlayer = () => (
    <Dropdown trigger={['click']} overlay={PlayerMenu}>
    <Button onClick={handlePlayerMenuClick}>{playerName}<DownOutlined /></Button>

  </Dropdown>
  );


  const ChooseInactivePlayer = () => (
    <Dropdown trigger={['click']} overlay={PlayerMenu}>
    <Button onClick={handlePlayerMenuClick}>{playerName}<DownOutlined /></Button>

  </Dropdown>
  );

  const ChoosePosition = () => (
    <Dropdown trigger={['click']} overlay={NFLPositionMenu}>
      <Button onClick={handlePositionMenuClick}>{positionName}<DownOutlined /></Button>

    </Dropdown>
  );

  const handleClickAdmin = (e) => {
    //console.log("click ", e);
    console.log("handle click Admin");
    console.log(e);
    console.log(e.key);
    setIsSuccess(false);
    setView(e.key);

  };

  /*const seasonTypeItems = [
    {
      label: "Calendar Year",
      key: 1
    },
    {
      label: "Regular Season",
      key: 2
    },
    {
      label: "Playoffs",
      key: 3
    }
    {
      label: "All-Star Game",
      key: 4
    },
    {
      label: " In-Season Tournement",
      key: 5
    } 

  ]
*/

  const componentMap = {
    "": null,
    Player: (
      <div>
        <div>{leagueName}</div>
        {leagueName!=='Select A League' && <ChooseTeam></ChooseTeam>} 
        {teamName!=='Select A Team' && <div>
          <Form onFinish={submitNewPlayer}> 
          {/* <Form onFinish = {(e) => submitNewPlayer(e,usr)}> */}
            <Form.Item label='Player First Name' name='p_fname' rules={[{required:true}]}>
              <Input />
            </Form.Item>
            <Form.Item label='Player Last Name' name='p_lname' rules={[{required:true}]}>
              <Input />
            </Form.Item>
            <Form.Item label='Player Position' name='p_pos' rules={[{required:true}]}>
              <Select placeholder="Select a Position" style={{ width: 200 }}  options={positionOptions}>
              </Select>
            </Form.Item>
            <Form.Item label='Rookie'  name='p_rookie' valuePropName="checked" initialValue={false}>
              <Checkbox >Rookie</Checkbox>
            </Form.Item>
            <Form.Item label='Add to Equity history week 0'  name='p_savehist' valuePropName="checked" initialValue={false}>
              <Checkbox >Yes</Checkbox>
            </Form.Item>
            <Form.Item label='Player Price' name='p_price' rules={[{required:false}]}>
              <InputNumber />
            </Form.Item>
            <Form.Item label='Player Dividend' name='p_dividend' rules={[{required:false}]}>
              <InputNumber />
            </Form.Item>
            {/*<Form.Item label='Player Number' name='p_num' rules={[{required:false}]}>
              <Input />
            </Form.Item>
            <Form.Item label='Player College' name='p_college' rules={[{required:false}]}>
              <Input />
            </Form.Item>
            <Form.Item label='Player Age' name='p_age' rules={[{required:false}]}>
              <Input />
            </Form.Item> */}
            <Form.Item>
              <Button type='primary' htmlType="submit">Submit</Button>
            </Form.Item>
          </Form>
          </div>}
      </div>
    ),
    PlayerTrade: (
      <div>
        <div>{leagueName}</div>
       {leagueName!=='Select A League' && <ChooseTeam></ChooseTeam>}
       {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <ChooseTeamNew></ChooseTeamNew>}
       {newTeamName!=="Select A Player" && <div>

          <Button type='primary' htmlType="submit" onClick={submitNewPlayerTeam}>Submit</Button>
      </div>}
      </div>
    ),
    PriceAndDiv: (
      <div>
       {leagueName!=='Select A League' && <ChooseTeam></ChooseTeam>}
       {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={submitNewPlayerPriceAndDividend}>
          <Form.Item label='Player Price' name='price' rules={[{required:true}]}>
            <InputNumber />
          </Form.Item>
          <Form.Item label='Player Dividend' name='dividend'>
            <InputNumber />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType="submit">Submit</Button>
          </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    TeamPriceAndDiv: (
      <div>
        <div>{leagueName}</div>
       {leagueName!=='Select A League' && <ChooseTeam></ChooseTeam>}
       {teamName!=='Select A Team' && <div>
       <Form onFinish={submitNewTeamPriceAndDividend}>
          <Form.Item label='Team Price' name='price' rules={[{required:true}]}>
            <InputNumber />
          </Form.Item>
          <Form.Item label='Team Dividend' name='dividend'>
            <InputNumber />
          </Form.Item>
          <Form.Item>
             <Button type='primary' htmlType="submit">Submit</Button>
          </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    Price: (
      <div>
       {leagueName!=='Select A League' && <ChooseTeam></ChooseTeam>}
       {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={submitNewPlayerPrice}>
          <Form.Item label='Player Price' name='price' rules={[{required:true}]}>
            <InputNumber />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType="submit">Submit</Button>
          </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    TeamPrice: (
      <div>
        <div>{leagueName}</div>
       {leagueName!=='Select A League' && <ChooseTeam></ChooseTeam>}
       {teamName!=='Select A Team' && <div>
       <Form onFinish={submitNewTeamPrice}>
          <Form.Item label='Team Price' name='price' rules={[{required:true}]}>
            <InputNumber />
          </Form.Item>
          <Form.Item>
             <Button type='primary' htmlType="submit">Submit</Button>
          </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    PlayerDiv: (
      <div>
        <div>{leagueName}</div>
       {leagueName!=='Select A League' && <ChooseTeam></ChooseTeam>}
       {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={submitPlayerDividend}>
           <Form.Item label='Player Dividend' name='price' rules={[{required:true}]}>
           <InputNumber />
           </Form.Item>
           <Form.Item>
             <Button type='primary' htmlType="submit">Submit</Button>
           </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    TeamDiv: (
      <div>
        <div>{leagueName}</div>
       {leagueName!=='Select A League' && <ChooseTeam></ChooseTeam>}
       {teamName!=='Select A Team' && <div>
       <Form onFinish={submitTeamDividend}>
           <Form.Item label='Team Dividend' name='price' rules={[{required:true}]}>
           <InputNumber />
           </Form.Item>
           <Form.Item>
             <Button type='primary' htmlType="submit">Submit</Button>
           </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    PosPlayerPrice: (
      <div>
       {leagueName!=='Select A League' && <ChoosePosition></ChoosePosition>}
       {positionName!=='Select A Position' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={submitNewPlayerPrice}>
          <Form.Item label='Player Price' name='price' rules={[{required:true}]}>
            <InputNumber />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType="submit">Submit</Button>
          </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    PosPlayerDiv: (
      <div>
        <div>{leagueName}</div>
       {leagueName!=='Select A League' && <ChoosePosition></ChoosePosition>}
       {positionName!=='Select A Position' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={submitPlayerDividend}>
           <Form.Item label='Player Dividend' name='price' rules={[{required:true}]}>
           <InputNumber />
           </Form.Item>
           <Form.Item>
             <Button type='primary' htmlType="submit">Submit</Button>
           </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    PosPriceAndDiv: (
      <div>
        <div>{leagueName}</div>
       {leagueName!=='Select A League' && <ChoosePosition></ChoosePosition>}
       {positionName!=='Select A Position' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={submitNewPlayerPriceAndDividend}>
          <Form.Item label='Player Price' name='price' rules={[{required:true}]}>
            <InputNumber />
          </Form.Item>
          <Form.Item label='Player Dividend' name='dividend'>
            <InputNumber />
          </Form.Item>
          <Form.Item>
             <Button type='primary' htmlType="submit">Submit</Button>
          </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    sportleaguelock: (
      <div>
     <Form onFinish={e=>restrictSportLeagueTrading(e,1)}>
         <Form.Item>
           <Button type='primary' htmlType="submit">Restrict Trading for {leagueName}</Button>
         </Form.Item>
       </Form>
    </div>
    ),
    fullteamlock: (
      <div>
      {<ChooseTeam></ChooseTeam>}
     {teamName!=='Select A Team' && <div>
     <Form onFinish={e=>restrictTeamTrading(e,1)}>
         <Form.Item>
           <Button type='primary' htmlType="submit">Restrict Trading for Team</Button>
         </Form.Item>
       </Form>
    </div>}
    </div>
    ),
    teamlock: (
      <div>
      <div>{leagueName}</div>
       {leagueName!=='Select A League' && <ChooseTeam></ChooseTeam>}
     {teamName!=='Select A Team' && <div>
     <Form onFinish={e=>restrictTeamStockTrading(e,1)}>
         <Form.Item>
           <Button type='primary' htmlType="submit">Restrict Trading for Team Stock Only</Button>
         </Form.Item>
       </Form>
    </div>}
    </div>
    ),
    NFLplayerlock: (
      <div>
        {<ChooseTeam></ChooseTeam>}
       {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={e=>restrictNFLplayerTrading(e,1)}>
           <Form.Item>
             <Button type='primary' htmlType="submit">Restrict Trading for Player</Button>
           </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    NFLpositionlock: (
      <div>
        <Form onFinish={e=>restrictNFLpositionTrading(e,1)}>
          <Form.Item label='Position' name='position' rules={[{required:true}]}>
            <Select placeholder="Select A Position" style={{ width: 200 }}  options={positionOptions}>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType="submit">Restrict Trading for Position</Button>
          </Form.Item>
       </Form>
      </div>
    ),
    sportleagueunlock: (
      <div>
     <Form onFinish={e=>restrictSportLeagueTrading(e,0)}>
         <Form.Item>
           <Button type='primary' htmlType="submit">Un-Restrict Trading for {leagueName}</Button>
         </Form.Item>
       </Form>
    </div>
    ),
    fullteamunlock: (
      <div>
      {<ChooseTeam></ChooseTeam>}
     {teamName!=='Select A Team' && <div>
     <Form onFinish={e=>restrictTeamTrading(e,0)}>
         <Form.Item>
           <Button type='primary' htmlType="submit">Un-Restrict Trading for Team</Button>
         </Form.Item>
       </Form>
    </div>}
    </div>
    ),
    teamunlock: (
      <div>
      <div>{leagueName}</div>
       {leagueName!=='Select A League' && <ChooseTeam></ChooseTeam>}
     {teamName!=='Select A Team' && <div>
     <Form onFinish={e=>restrictTeamStockTrading(e,0)}>
         <Form.Item>
           <Button type='primary' htmlType="submit">Un-Restrict Trading for Team Stock Only</Button>
         </Form.Item>
       </Form>
    </div>}
    </div>
    ),
    NFLplayerunlock: (
      <div>
        {<ChooseTeam></ChooseTeam>}
       {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={e=>restrictNFLplayerTrading(e,0)}>
           <Form.Item>
             <Button type='primary' htmlType="submit">Un-Restrict Trading for Player</Button>
           </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    NFLpositionunlock: (
      <div>
        <Form onFinish={e=>restrictNFLpositionTrading(e,0)}>
          <Form.Item label='Position' name='position' rules={[{required:true}]}>
            <Select placeholder="Select A Position" style={{ width: 200 }}  options={positionOptions}>
            </Select>
          </Form.Item>
         <Form.Item>
           <Button type='primary' htmlType="submit">Un-Restrict Trading for Position</Button>
         </Form.Item>
       </Form>
      </div>
    ),
    stockactive: (
      <div>
       {<ChooseInactivePlayer></ChooseInactivePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={e=>activatePlayerStock(e,1)}>
           <Form.Item>
             <Button type='primary' htmlType="submit">Activate Player Stock</Button>
           </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    stockinactive: (
      <div>
        {<ChooseTeam></ChooseTeam>}
       {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={e=>activatePlayerStock(e,0)}>
           <Form.Item>
             <Button type='primary' htmlType="submit">Un-Activate Player Stock</Button>
           </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    FFleaguelock: (
      <div>
        <Form onFinish={e=>restrictFFleagueTrading(e,0)}>
         <Form.Item>
           <Button type='primary' htmlType="submit">Lock Trading for {leagueName} FF Leagues</Button>
         </Form.Item>
       </Form>
      </div>
    ),
    FFleagueunlock: (
      <div>
        <Form onFinish={e=>restrictFFleagueTrading(e,1)}>
         <Form.Item>
           <Button type='primary' htmlType="submit">Un-Lock Trading for {leagueName} FF Leagues</Button>
         </Form.Item>
       </Form>
      </div>
    ),
    stockhistorysave: (
      <div>
        <div>Do at start for Week 0.</div>
      <Form onFinish={e=>saveStockHistory(e)}>
        <Form.Item label="Week" name="week" rules={[{ required: true, message: "Provide a week (subtimeframe)." }]}>
        <InputNumber min={0} />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType="submit">Save Stock History</Button>
        </Form.Item>
     </Form>
    </div>
    ),
    playerhistoryupdate: (
      <div>
        {<ChooseTeam></ChooseTeam>}
       {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
      <Form onFinish={e=>updateSingleStockHistory(e)}>
        <Form.Item label="Week" name="week" rules={[{ required: true, message: "Provide a week (subtimeframe)." }]}>
        <InputNumber min={0} />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType="submit">Save Stock History</Button>
        </Form.Item>
     </Form>
     </div>}
    </div>
    ),
    porthistorysave: (
      <div>
        <div>Do at start for Week 0.</div>
        <div>Do not submit this until prices are updated on Tuesday -- do after port roster history save.</div>
      <Form onFinish={e=>savePortHistory(e)}>
        <Form.Item label="Week" name="week" rules={[{ required: true, message: "Provide a week (subtimeframe)." }]}>
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType="submit">Save Portfolio History</Button>
        </Form.Item>
     </Form>
    </div>
    ),
    portstockhistsave: (
      <div>
      <div>Do NOT do for week 0.</div>
      <Form onFinish={e=>savePortEquityHistory(e)}>
        <Form.Item label="Week" name="week" rules={[{ required: true, message: "Provide a week (subtimeframe)." }]}>
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType="submit">Save Portfolio Stock History</Button>
        </Form.Item>
     </Form>
    </div>
    ),
    roststockhistsave: (
      <div>
        <div>Do not submit this until game stats are updated for week.</div>
      <Form onFinish={e=>savePortRosterHistory(e)}>
        <Form.Item label="Week" name="week" rules={[{ required: true, message: "Provide a week (subtimeframe)." }]}>
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType="submit">Save Portfolio Roster History</Button>
        </Form.Item>
     </Form>
    </div>
    ),
    incrementmults: (
      <div>
        <div>Do not submit this until portfolio roster history has been saved.</div>
      <Form onFinish={e=>incrementMultipliers(e)}>
        <Form.Item>
          <Button type='primary' htmlType="submit">Increment Multipliers</Button>
        </Form.Item>
     </Form>
    </div>
    ),
    paydividends: (
      <div>
      <Form onFinish={e=>payoutDividends(e)}>
        <Form.Item>
          <Button type='primary' htmlType="submit">Payout Dividends</Button>
        </Form.Item>
     </Form>
    </div>
    ),
    timeframecreate: (
      <div>
        {<ChooseTeam></ChooseTeam>}
        {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
        {playerName!=="Select A Team" && <div>
      <Form onFinish={e=>exportStockToTimeframe(e)}>
        <Form.Item label="Year" name="year" rules={[{ required: true, message: "Provide a Year." }]}>
          <InputNumber min={2023} />
        </Form.Item>
        <Form.Item label="Timeframe" name="timeframe">
          <Select placeholder="Select a Timeframe" style={{ width: 200 }}>
            <Option value={1}>Calendar Year</Option>
            <Option value={2}>Regular Season</Option>
            <Option value={3}>Playoffs</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType="submit">Export to Timeframe</Button>
        </Form.Item>
     </Form>
     </div>}
    </div>
    ),
    timeframecreateteamstock: (
      <div>
        {<ChooseTeam></ChooseTeam>}
        {teamName!=="Select A Team" && <div>
      <Form onFinish={e=>exportTeamStockToTimeframe(e)}>
        <Form.Item label="Year" name="year" rules={[{ required: true, message: "Provide a Year." }]}>
          <InputNumber min={2023} />
        </Form.Item>
        <Form.Item label="Timeframe" name="timeframe">
          <Select placeholder="Select a Timeframe" style={{ width: 200 }}>
            <Option value={1}>Calendar Year</Option>
            <Option value={2}>Regular Season</Option>
            <Option value={3}>Playoffs</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType="submit">Export to Timeframe</Button>
        </Form.Item>
     </Form>
     </div>}
    </div>
    ),
    timeframecreateteam: (
      <div>
        {<ChooseTeam></ChooseTeam>}
        {teamName!=="Select A Team" && <div>
      <Form onFinish={e=>exportTeamToTimeframe(e)}>
        <Form.Item label="Year" name="year" rules={[{ required: true, message: "Provide a Year." }]}>
          <InputNumber min={2023} precision={0}/>
        </Form.Item>
        <Form.Item label="Timeframe" name="timeframe">
          <Select placeholder="Select a Timeframe" style={{ width: 200 }}>
            <Option value={1}>Calendar Year</Option>
            <Option value={2}>Regular Season</Option>
            <Option value={3}>Playoffs</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType="submit">Export to Timeframe</Button>
        </Form.Item>
     </Form>
     </div>}
    </div>
    ),
    timeframeinc: (
      <Form onFinish={e=>{incrementTimeframe(e)}}>
        <Form.Item>
          <Button type='primary' htmlType="submit">Increment SubTimeframe</Button>
        </Form.Item>
      </Form>
    ),
    subtimeframeiupd: (
      <Form onFinish={e=>{updateSubtimeframe(e)}}>
        <Form.Item label="Subtimeframe" name="subtimeframe">
          <InputNumber min={0} max={53} precision={0}/>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType="submit">Update SubTimeframe</Button>
        </Form.Item>
      </Form>
    ),
    autoupdatestocks: (
      <Form onFinish={e=>{autoUpdateStocks(e)}}>
      <Form.Item>
        <Button type='primary' htmlType="submit">Update Stocks</Button>
      </Form.Item>
    </Form>
    ),
    autoupdateteamstocks: (
      <Form onFinish={e=>{autoUpdateTeamStocks(e)}}>
      <Form.Item>
        <Button type='primary' htmlType="submit">Update Team Stocks</Button>
      </Form.Item>
    </Form>
    )
  };

  return (
      <div>
        <Select defaultValue={defaultTimeframe.league} style={{ width: 200 }} onChange={value=>{leagueUpdate(value)}}>
            <Option value={1}>NFL</Option>
            <Option value={6}>NCAAM</Option>
          </Select>
          <Select defaultValue={defaultTimeframe.season} style={{ width: 200 }} onChange={value=>{seasonUpdate(value)}}>
            <Option value={2}>Regular Season</Option>
            <Option value={3}>Playoffs</Option>
          </Select>
        <Menu onClick={handleClickAdmin} mode='horizontal'>
            {/* <Menu.Item key="Team"><Button>Add a Team</Button></Menu.Item> */}
            <Menu.Item key="Player"><Button>Add a Player</Button></Menu.Item>
            {<Menu.Item key="PlayerTrade"><Button>Update Player Team</Button></Menu.Item>}
            {/*<Menu.Item key="TeamUpdate"><Button>Update Team Name</Button></Menu.Item>*/}
            {/*<Menu.Item key="PlayerUpdate"><Button>Update Player Name</Button></Menu.Item>*/}
        </Menu>
        {league===1 && <Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="PriceAndDiv"><Button>Set a Player Price & Div</Button></Menu.Item>
            <Menu.Item key="TeamPriceAndDiv"><Button>Set Team Price & Div</Button></Menu.Item>
            <Menu.Item key="PosPriceAndDiv"><Button>Set Position Price & Div</Button></Menu.Item>
        </Menu>}
        {league===1 && <Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="Price"><Button>Set a Player Price</Button></Menu.Item>
            <Menu.Item key="PlayerDiv"><Button>Set Player Dividend</Button></Menu.Item>
            <Menu.Item key="PosPlayerPrice"><Button>Set a Position Price</Button></Menu.Item>
            <Menu.Item key="PosPlayerDiv"><Button>Set Position Dividend</Button></Menu.Item>
        </Menu>}
        <Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="TeamPrice"><Button>Set a Team Price</Button></Menu.Item>
            <Menu.Item key="TeamDiv"><Button>Set Team Dividend</Button></Menu.Item>
        </Menu>
        {league===1 && <Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="stockactive" onClick={getInactivePlayers}><Button>Activate NFL Player Stock</Button></Menu.Item>
            <Menu.Item key="stockinactive"><Button>Make NFL Player Stock Inactive</Button></Menu.Item>
          </Menu>}
        <Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="sportleaguelock" ><Button>Lock Trading for {leagueName}</Button></Menu.Item>
            <Menu.Item key="sportleagueunlock"><Button>Un-Lock Trading for {leagueName}</Button></Menu.Item>
        </Menu>
        <Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="fullteamlock"><Button>Lock Trading for {leagueName} Team</Button></Menu.Item>
            <Menu.Item key="fullteamunlock"><Button>Un-Lock Trading for {leagueName} Team</Button></Menu.Item>
        </Menu>
        <Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="teamlock"><Button>Lock Trading for Team Stock</Button></Menu.Item>
            <Menu.Item key="teamunlock"><Button>Un-Lock Trading for Team Stock</Button></Menu.Item>
        </Menu>
        {league===1 && <Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="NFLplayerlock"><Button>Lock Trading for NFL Player</Button></Menu.Item>
            <Menu.Item key="NFLplayerunlock"><Button>Un-Lock Trading for NFL Player</Button></Menu.Item>
        </Menu>}
        {league===1 && <Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="NFLpositionlock"><Button>Lock Trading for NFL Position Group</Button></Menu.Item>
            <Menu.Item key="NFLpositionunlock"><Button>Un-Lock Trading for NFL Position Group</Button></Menu.Item>
        </Menu>}
        <Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="FFleaguelock"><Button>Lock Trading for {leagueName} FF Leagues</Button></Menu.Item>
            <Menu.Item key="FFleagueunlock"><Button>Un-Lock Trading for {leagueName} FF Leagues</Button></Menu.Item>
        </Menu>
        <Menu onClick={handleClickAdmin} mode='horizontal'>
          <Menu.Item key="stockhistorysave"><Button>Save stock history</Button></Menu.Item>
          {league===1 && <Menu.Item key="playerhistoryupdate"><Button>Save single player stock history</Button></Menu.Item>}
        </Menu>
        <Menu onClick={handleClickAdmin} mode='horizontal'>
          <Menu.Item key="portstockhistsave"><Button>Save port equity history</Button></Menu.Item>
          {league===1 && season===3 && <Menu.Item key="roststockhistsave"><Button>Save roster history</Button></Menu.Item>}
        </Menu>
        <Menu onClick={handleClickAdmin} mode='horizontal'>
          {league===1 && season===3 && <Menu.Item key="paydividends"><Button>Payout Dividends</Button></Menu.Item>}
          {league===1 && season===3 && <Menu.Item key="incrementmults"><Button>Increment Multipliers</Button></Menu.Item>}
          <Menu.Item key="porthistorysave"><Button>Save portfolio history</Button></Menu.Item>
          {/*<Menu.Item key="rosthistorysave"><Button>Save roster value history</Button></Menu.Item> */}

        </Menu>
        <Menu onClick={handleClickAdmin} mode='horizontal'>
          <Menu.Item key="timeframecreate"><Button>Create Stock for New Timeframe</Button></Menu.Item>
          <Menu.Item key="timeframecreateteamstock"><Button>Create Team Stock for New Timeframe</Button></Menu.Item>
          <Menu.Item key="timeframecreateteam"><Button>Create Stocks from Team for New Timeframe</Button></Menu.Item>
        </Menu>
        <Menu onClick={handleClickAdmin} mode='horizontal'>
          <Menu.Item key="timeframeinc"><Button>Increment Timeframe</Button></Menu.Item>
          <Menu.Item key="subtimeframeupd"><Button>Update Subtimeframe</Button></Menu.Item>
        </Menu>
        <Menu onClick={handleClickAdmin} mode='horizontal'>
          <Menu.Item key="autoupdatestocks"><Button>Auto Update Stocks</Button></Menu.Item>
          <Menu.Item key="autoupdateteamstocks"><Button>Auto Update Team Stocks</Button></Menu.Item>
        </Menu>
        {isSuccess && <div>{prevView} Update Successful</div>}
        {isSuccess && <Button onClick={repeatAction}>Repeat Action</Button>}
        {view!=="All" && <div>{view}</div>}
        {componentMap[view]}
        <div>NOTE: Save equity history and portfolio history for week 0. NOT portfolio equity history.</div>
        <div>NOTE: Then save equity history, portfolio history, and portfolio equity history for current week (starting with 1) before updating prices.</div>
        <div> Can save equity history at any time for the next week after updating the prices. </div>
        <div> Can save port hist anytime but timing will change values based on transaction fee losses. Do not early update next week prices (ex; bc of Thurs Night) before saving port hist. </div>
        <div> Wait till after MNF lock for port eq hist.</div>
      </div>
  );
  
}

export default AdminStock;
