import React, {  useState } from "react";
import {Button} from "antd";


const BodyHome = (props) => {



  const [showMore, setShowMore] = useState(false);


  const showMoreString = {
    false: "Show More",
    true: "Show Less"
  }







  return (
    <div>
        <p>Welcome to SpoxMarket, the stock market for Sports!</p>
        <p align='left'>
          This site allows you to play fantasy sports with a stock market twist.
          <br></br>Create portfolios that are given an inital starting budget and try to end the season with the higest valued portfolio in your league.
          <br></br><br></br>You can increase your Portfolio's value by trading shares of players who you think will outperform their stock price.
          <br></br>Note that each league may have a transaction cost for each trade that will reduce the Portfolio's value.
          <br></br><br></br>Being fantasy sports, the only real money involved is for prizes and entry costs. The rest of the values for stock prices, portfolio cash, and transaction fees, have no real world meaning.

          <br></br><br></br><b>To play, first join a league (SpoxMarket NFL 2024 is the official sitewide playoff league) or create your own and start creating your Portfolio.</b>
          {/*<span> <br></br><br></br><b>To play, first join a league (SpoxMarket March Madness 2024 is the official sitewide playoff league), then create a Portfolio to join that league.</b>

            {<p align='left'>
              <b>Scoring Rules for March Madness Teams:</b>
              <br></br>$1 for making the field of 64 (Teams that lose the first 4 get $0.50 instead of $1)
              <br></br>$2 for a Round 1 win and another $4 for a win in Round 2
              <br></br>$8  additional for winning in the Sweet 16
              <br></br>$16 additional for winning in the Elite 8
              <br></br>$32 for making it to the Championship Game
              <br></br>$64 for winning the Championship (this gives the winning team a final price of $127)
              <br></br><br></br>The Tournament plays two rounds each weekend (Thurs-Sun). <b>Trading will not be enabled between rounds on the same weekend.</b>
            </p>}
            </span>*/}

          <br></br><b>Last year we awarded $150 in winnings and will do even more this  year!</b>
          <br></br>In order to receive any winnings, you must verify your email and enter your Venmo username into your profile.

          {/*
          <br></br><br></br><b>Congrats to our 2023 NFL Winners! : </b>
          <br></br><br></br><b>Congrats to @iitsmikester21 who had the highest performing portfolio of the season, earning a $50 prize!</b>
          <br></br><b>Congrats to @Janko who had the highest performing portfolio of the NFL Playoffs, earning a $20 prize!</b>
          <br></br><br></br>Congrats to @iitsmikester21 who had the highest performing portfolio at the Week 9 midway point, earning a $20 prize!
          <br></br>Congrats to @JakeK09 who had the highest performing portfolio in the 2nd half of the season, earning a $20 prize!
          <br></br>Congrats to @Janko who was the highest gainer in Week 18 and earned a $10 prize!
          <br></br>Congrats to @JakeK09 who was the highest gainer in Week 15 and earned a $10 prize!
          <br></br>Congrats to @sruppert12 who was the highest gainer in Week 4 and earned a $10 prize!
          <br></br>Congrats to @Mintyhippo25 who was the highest gainer in Week 1 and earned a $10 prize!
          */}

          <br></br><br></br>The football format closely resembles that of March Madness bracket pools, where you join groups and compete for positioning within those groups, but your picks are independent of the other group members.
          <br></br>This differs from traditional fantasy football where you have a draft that ensures no two group members can have the same player.

        </p>
        <Button type='link' onClick={()=>{setShowMore(!showMore)}}>{showMoreString[showMore]}</Button>
        {showMore && <span>
        <p align = 'left'>
          Each player creates entries called Portfolios. Each Portfolio has a starting budget
          based on the league they are joining, and you can make market transactions in your Portfolio to try to increase its value.
          At the end of the season, the highest valued portfolio wins! The final value of each player is
          based on their statistical output for the season.
        </p>
        {/*<p align='left'>Playoffs: There is a Site-Wide League named '<b>SpoxMarket NFL Playoffs 2023</b>'. Everyone gets <b>one free entry</b> into this contest.
         There are no transaction costs for this league!
        You are also free to create and join your own leagues with different settings and compete for your own monetary or bragging rights prizes.
        Finally, there may be other prizes awarded to users of the site based on hidden metrics, so make sure to try things out!</p>*/}

        <p align='left'>
          Spox (Sport Stocks) earn money when the associated player or team performs well. The earnings they recieve are the Stock's dividend. At the end of the regular season,
          the Stock Price will exactly match the dividends as there will no longer be potential for increased earnings.
          <br></br><b>Each position earns its dividend the following ways:</b>
          <br></br>$1 for every 25 Passing Yards
          <br></br>$4 for every Passing TD
          <br></br>$1 for every 8 Rushing Yards
          <br></br>$1 for every Reception
          <br></br>$1 for every 10 Receiving Yards
          <br></br>$6 for every Rushing or Receiving TD
          <br></br>-$2 for every Turnover (Interception or Lost Fumble)
          <br></br>Kickers recieve $3 for every made field goal and an extra $1 for every 10 yards above 30 that the kick was made from
          <br></br>$1 for each Extra Point made
          <br></br>-$1 for each Extra Point Missed and for each Field Goal under 40 Yards Missed.
          <br></br>Teams: Regular Season: get $10 per win, $25 for making the playoffs, an additional $25 for winning their division, and another final $25 for being the #1 seed in their conference.
          <br></br>In the playoffs, Teams get $10 for a Wild Card Round win, $20 for a Divisional Round win, $30 for a Conference Championship, and $50 for winning the Super Bowl.
        </p>

        <p align='left'>Regular Season: There is a Site-Wide League named '<b>SpoxMarket NFL 2024</b>'. Everyone gets <b>one free entry</b> into
          this contest and the winnerat the end of the regular season will get <b>$50 (real!) dollars!</b> This league will have a
          transaction fee for trade of $10 per trade plus 0.5% of the total trade value which gets taken from your portfolio's balance.
          However, all trades made before the start of the season will be free! There is also a Buy & Hold version of this league that won't
          allow any mid-season trades. So you buy pre-season and then see how you do at the end. It can be thought of as the BestBall
          version of SpoxMarket.You are also free to create and join your own leagues with different settings and compete for your own
          monetary or bragging rights prizes. Finally, there will be other prizes awarded to users of the site based on hidden metrics, so
          make sure to try things out!
        </p> {/*Maybe most transactions and most referrals, plus if winner is a referall */}
        {/*<p align='left'>For the NFL Playoffs, there is a 1x multiplier on earnings in the Wild Card Round, followed by a 2x, 3x, and 4x multiplier for the Divisional, Conference, and Super Bowl rounds, respectively.</p> */}
        <p align='left'>Some players may have trading halted due to their game starting or other news that will require an updated price.</p>
        <p align='left'>This site is still in beta progress, so please excuse the design (or lack thereof) and please feel free to give feedback whether its bugs that you encounter,
          players you want to see added to the market, or any other suggestions for future expansion of the site by sending an email to <b>spoxmarket@gmail.com</b>
        </p>
        </span>}
      </div>
  );
}

export default BodyHome;
